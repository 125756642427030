<template>
  <div class="chat">
    <div style="width: 50%; margin: auto">
      <div class="container">
        <my-video :sources="video.sources" :options="video.options"></my-video>
      </div>
    </div>

    <div class="downloads">
      <h3>Downloads</h3>
      <a href="/video/Gianni_Linn_live.mp4" download
        >Recording of live stream (723 MB)</a
      ><br />
      <a href="/video/Gianni_Linn_von_Hanno.mp4" download>
        Hanno's wedding video (13 MB)</a
      ><br />
      <a href="/video/Bandonly_FullHD.mp4" download>Music video DHV (94 MB)</a
      ><br />
      <div>
        <h4>Congratulations music video</h4>
        <a href="/video/Gianni_Linn_FullHD.mp4" download>full (159 MB)</a>&nbsp;
        <a href="/video/Gianni_Linn_medium.mp4" download>medium (77 MB)</a
        >&nbsp;
        <a href="/video/Gianni_Linn_small.mp4" download>base (40 MB)</a>&nbsp;
        <a href="/video/Gianni_Linn_verysmall.mp4" download>low (30 MB)</a>

        <h4>Congratulations with reaction</h4>
        <a href="/video/Gianni_Linn_reaction_FullHD.mp4" download
          >full (373 MB)</a
        >&nbsp;
        <a href="/video/Gianni_Linn_reaction_medium.mp4" download
          >medium (80 MB)</a
        >&nbsp;
        <a href="/video/Gianni_Linn_reaction_small.mp4" download
          >base (55 MB)</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import myVideo from "vue-video";

export default {
  components: {
    myVideo,
  },
  data() {
    return {
      video: {
        sources: [
          {
            src: "https://lg.ja2017.de/video/Gianni_Linn_medium.mp4",
            type: "video/mp4",
          },
        ],
        options: {
          autoplay: false,
          volume: 0.6,
          poster: "https://lg.ja2017.de/heart.png",
        },
      },
    };
  },
};
</script>

<style>
.video-wrapper iframe,
.video-wrapper object,
.video-wrapper embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.downloads {
  margin-top: 30px;
  color: black;
}
.downloads a {
  color: black;
  margin-bottom: 15px;
}
</style>
